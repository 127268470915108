/* Container for the entire form */
.update-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f8f9fa;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

/* Form styling */
.update-form {
    display: flex;
    flex-direction: column;
}

/* Form group styling */
.form-group {
    margin-bottom: 20px;
}

/* Label styling */
.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

/* Input and select styling */
.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

/* File input styling */
#file {
    padding: 5px 0;
}

/* Image preview container */
.image-preview-container {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

/* Image preview styling */
.image-preview {
    max-width: 100%;
    max-height: 200px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Submit button styling */
.submit-button {
    position: relative;
    background-color: #007bff;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Loading spinner styling */
.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
