.register {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
  
  }
  
  .main-form {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 10px;
    margin-top: 30%;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    padding: 10px;
    margin-bottom: 10px;
    width: calc(100% - 0px); /* Adjusted width to account for padding */
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    box-sizing: border-box; /* Ensures padding is included in the width */
  }
  
   .main-form  button {
    padding: 10px;
    margin-top: 10px;
    width: calc(100% - 0px); /* Adjusted width to account for padding */
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-sizing: border-box; /* Ensures padding is included in the width */
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 10px;
  }
  
  .message p {
    margin: 0;
  }
  